.container {
  display: flex;
  flex-direction: row;
}
.textArea {
  min-width: 40vw;
  height: 100vh;
  background-color: #f8ecc2;
  border: none;
  outline: none;
  padding: 5px;
}
.texArea {
  min-width: 40vw;
  padding: 20px;
  width: 100%;
}
.settings {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}
.title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.topBar {
  display: flex;
  flex-direction: row;
}
